import { PersistenceLevel, Store } from '@/core/flux.service';
import type { AnyProperty } from '@/utilities.types';
import type { InstanceConfig, TableConfig } from '@/vantage/types/TableConfig.types';
import type { ExtensionSchema } from '@/vantage/types/Extension.types';
import type { SeeqUser } from '@/vantage/utilities';
import { TableDefinitionOutputV1 } from '@/sdk/model/TableDefinitionOutputV1';

export class VantageWorkbookStore extends Store {
  persistenceLevel: PersistenceLevel = 'NONE';
  static readonly storeName = 'sqVantageWorkbook';

  initialize(): void {
    this.state = this.immutable({
      workbookConfig: undefined,
      user: undefined,
      evidenceTableDefinition: undefined,
    });
  }

  get workbookConfig(): InstanceConfig | undefined {
    return this.state.get('workbookConfig');
  }

  get extensions(): ExtensionSchema[] | undefined {
    return this.workbookConfig?.extensions;
  }

  get tableConfig(): TableConfig | undefined {
    return this.workbookConfig?.tableConfig;
  }

  get tableIds(): AnyProperty<string> | undefined {
    return this.workbookConfig?.tableIds;
  }

  get workbookId(): string | undefined {
    return this.workbookConfig?.workbookId;
  }

  get user(): SeeqUser | undefined {
    return this.state.get('user');
  }

  get evidenceTableDefinition(): TableDefinitionOutputV1 | undefined {
    return this.state.get('evidenceTableDefinition');
  }

  protected readonly handlers = {
    SET_WORKBOOK_CONFIG: (payload: { workbookConfig: InstanceConfig }) =>
      this.state.set('workbookConfig', payload.workbookConfig),
    SET_USER: (payload: { user: SeeqUser }) => this.state.set('user', payload.user),
    SET_EVIDENCE_TABLE_DEFINITION: (payload: { evidenceTableDefinition: TableDefinitionOutputV1 }) =>
      this.state.set('evidenceTableDefinition', payload.evidenceTableDefinition),
  };
}
