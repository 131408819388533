/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.4.0-v202501092220-CDH
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqPluginsApiClass {
 
   constructor() {}

  /**
   * @summary Delete a plugin
   * @param {string} id - The ID of the plugin
   */
  public deletePlugin(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/plugins/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get a plugin file
   * @param {string} id - The ID of the plugin
   * @param {string} fileName - The name of the file from the plugin
   */
  public getFile(
    {
      id,
      fileName
    } : {
      id: string,
      fileName: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    if (isNil(fileName)) {
      throw new Error("'fileName' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/plugins/${encodeURIComponent(String(id))}/files/${encodeURIComponent(String(fileName))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise;
  }

  /**
   * @summary Get a collection of plugins
   * @param {string} [nameSearch] - Search text by which to filter plugins&#x27; names.
   * @param {string} [identifierSearch] - Search text by which to filter plugins&#x27; identifiers.
   * @param {string} [categorySearch] - Search text by which to filter plugins&#x27; categories.
   * @param {string} [sortOrder=name asc] - A field by which to order the plugins followed by a space and &#x27;asc&#x27; or &#x27;desc&#x27;. Field name can be one of: name, identifier, category
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   * @param {boolean} [includeArchived=false] - Whether archived plugins should be included
   */
  public getPlugins(
    {
      nameSearch,
      identifierSearch,
      categorySearch,
      sortOrder,
      offset,
      limit,
      includeArchived
    } : {
      nameSearch?: string,
      identifierSearch?: string,
      categorySearch?: string,
      sortOrder?: string,
      offset?: number,
      limit?: number,
      includeArchived?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/plugins`,

      params: omitBy({
        ['nameSearch']: nameSearch,
        ['identifierSearch']: identifierSearch,
        ['categorySearch']: categorySearch,
        ['sortOrder']: sortOrder,
        ['offset']: offset,
        ['limit']: limit,
        ['includeArchived']: includeArchived
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.PluginOutputListV1>;
  }

  /**
   * @summary Upload a plugin
   * @param {Blob} [file]
   * @param {boolean} [inDevelopment=false] - Whether this item is in Development Mode, meaning that it is not allowed to be shared with any other users. Non-administrators may only install Plugins in Development Mode.
   */
  public uploadPlugin(
    {
      file,
      inDevelopment
    } : {
      file?: File,
      inDevelopment?: boolean
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    const formData = new FormData();
    formData.append('file', file as File, file?.name);

    return getAxiosInstance().post(
      APPSERVER_API_PREFIX + '/plugins',
      formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...extraHttpRequestConfig,
    }) as AxiosPromise<models.StatusMessageBase>;
  }

}


export const sqPluginsApi = new sqPluginsApiClass();
